.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  10% { opacity: 0; }
}


:focus:not([class*="chakra-"]) {
  outline: none;
}

html, body {
  height: 100%;
  margin: 0;
}

/* In your CSS or stylesheet */
/* .white-icon {
  color: white !important; 

.black-icon {
  color: black !important; 
} */

@keyframes moveText {
    0% {
        transform: translateX(100%); /* Starts from the right end */
    }
    100% {
        transform: translateX(-100%); /* Moves entirely through the left */
    }
}

.moving-text {
    animation: moveText 10s linear infinite;
}